.loading__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15rem;
}

.spacer {
    margin-top: 1.17rem;
}

.listItems:hover {
    background-color: rgb(94, 53, 177);
    color: white;
}

.app__container {
    margin-left: 2rem;
}


.collapse {
    color: blue;
}

.collapse:hover {
    cursor: pointer;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}


.container {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    margin-top: 1rem;
}


.form {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    width: 80vw;
}

.form_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form_div>label {
    font-size: larger;
}

.form_list {
    margin: 0.2rem;
}

button,
.button {
    margin: 1rem;
    background-color: #5E35B1;
    color: white;
    border: none;
    padding: 15px 32px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 2rem;
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
}

.dotted {
    border-top: 3px dashed #bbb;
    width: 100%;
}


.row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.small-button {
    margin: 1rem;
    background-color: #5E35B1;
    color: white;
    border: none;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.medium-button {
    margin: 1rem;
    background-color: #5E35B1;
    color: white;
    border: none;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.row_spacer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.column {
    padding: 1em;
}

.notification_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.three_grid {
    display: grid;
    grid-column: 3fr 1fr 1fr;
}

.selected_button {
    margin: 1rem;
    background-color: #5E35B1;
    color: white;
    border: none;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.unselected_button {
    margin: 1rem;
    background-color: white;
    color: #5E35B1;
    border: none;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.expanded_column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: max-content;
    font-size: 10px;
}

.column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

}

.icon_container {
    padding: 1em 3em;
    color: white;
    border-radius: 1em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    -ms-border-radius: 1em;
    -o-border-radius: 1em;
    transition: transform .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.icon_container:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
}

.max-width {
    width: 40vw;
    padding: 0 1em;
}

.navbar_maxcontainer {
    margin-left: 60vw;
}

@media only screen and (max-width: 1071px) {
    .navbar_maxcontainer {
        margin-left: 40vw;
    }
}
@media only screen and (max-width: 723px) {
    .navbar_maxcontainer {
        margin-left: 20vw;
    }
}