.history__container {
  padding: 1rem;
}

.dialog__container {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: start;
}

.historylist__container_index {
  font-weight: bold;
  margin-right: 1em;
}

.add-wallet {
  padding: 1rem;
}
